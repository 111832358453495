import { getData, postData } from './apiCall';
import jwt_decode from 'jwt-decode';

export const getRecognitionData = async ({ queryKey: [_key, authToken, messageId] }) => {
    const url = messageId ? `teams/task-module/recognition?messageId=${messageId}` : `teams/task-module/recognition`;
    try {
        const response = await getData(url, authToken);
        return response?.data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const getBonusData = async ({ queryKey: [_key, authToken] }) => {
    try {
        const response = await getData(`teams/task-module/bonus`, authToken);
        return response?.data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const enhanceMessage = async ({ reason, authToken }) => {
    const payload = {
        message: reason,
    };
    try {
        const path = 'ai/enhance-text';
        const { data } = await postData(path, payload, authToken);
        return data.message;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};
export const getUsers = async (payload, authToken) => {
    try {
        const response = await postData('api/v2/dashboard/user/compact/list', payload, authToken);
        return response?.data?.result;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const getChannels = async (authToken) => {
    const decode = jwt_decode(authToken);
    try {
        const response = await getData(`api/v1/slack/${decode['team-id']}/channels`, authToken);
        return response?.data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const getPulseData = async ({ queryKey: [_key, authToken, responseId] }) => {
    try {
        const response = await getData(`teams/task-module/pulse/startAnswer?responseId=${responseId}`, authToken);
        return response?.data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};

export const getGifList = async ({ queryKey: [_key, authToken, rewardType] }) => {
    try {
        const response = await getData(`recognition/gif-list?rewardType=${rewardType}`, authToken);
        return response?.data;
    } catch (error) {
        throw new Error(error?.response?.data?.message);
    }
};
